<template>
  <base-section id="aboutourproduct">
    <base-section-heading
      color="grey lighten-2"
      title="Servicios"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',
    data: () => ({
      cards: [
        {
          title: 'Techos falsos',
          // subtitle: 'Subtitulo',
          text: 'Son una excelente opción para agregar otro plano de diseño a tus proyectos.',
          callout: '1',
        },
        {
          title: 'Pladur',
          // subtitle: 'Subtitulo',
          text: 'Es un material de construcción utilizado para la ejecución de tabiques interiores y revestimientos de techos y paredes. Suele utilizarse en forma de placas, paneles o tableros industrializados.',
          callout: '2',
        },
        {
          title: 'Impermeabilización',
          // subtitle: 'Subtitulo',
          text: 'Son sustancias o compuestos químicos que tienen como objetivo detener el agua, impidiendo su paso, y son muy utilizados en el revestimiento de piezas y objetos que deben ser mantenidos secos.',
          callout: '3',
        },
        {
          title: 'Reformas',
          // subtitle: 'Subtitulo',
          text: 'Realizamos todo tipo de reformas en general.',
          callout: '4',
        },
        {
          title: 'Pinturas',
          // subtitle: 'Subtitulo',
          text: 'Realizamos Desinfección de paredes con humedades, todo tipo de pinturas y alisado en paredes.',
          callout: '6',
        },
      ],
    }),
  }
</script>
